import React from "react";
import { MdOpenInNew } from "react-icons/md";
import ClosedInvoices from "../components/ClosedInvoices";
import OpenPurchaseDocuments from "../components/OpenPurchaseDocuments";

const Purchase_document = () => {
  return (
    <div className=" pl-2 lg:pl-20 pr-6 lg:pr-20">
      <h1 className=" text-3xl  text-white w-full text-center pb-10 pt-6">
        <span className=" text-nr">Purchase</span> Documents
      </h1>
      <h1 className=" text-3xl  text-white ">
        <span className=" text-nr">Open</span> Purchase Documents
      </h1>
      <OpenDoucment />
      <h1 className=" text-3xl  text-white  mt-5">
        <span className=" text-nr">Closed</span> Purchase Documents
      </h1>
      <ClosedDoucment />
    </div>
  );
};
const OpenDoucment = () => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return <OpenPurchaseDocuments />;
};
const ClosedDoucment = () => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return <ClosedInvoices />;
};
export default Purchase_document;
