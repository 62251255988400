import React, { useState } from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Topbar from './layout/Topbar';
import Sidebar from './layout/Sidebar';
import Home from './pages/Home';
import Purchase_document from './pages/Purchase_document';
import Sales_document from './pages/Sales_document';
import NewDocument from './pages/NewDocument';
import ResourceSwap from './pages/ResourceSwap';

import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import {
  chain,
  configureChains,
  createClient,
  useAccount,
  WagmiConfig,
  Chain,
  useContractRead
} from 'wagmi';
import { useContract, useProvider } from 'wagmi'
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import configData from "./utils/Config.js"

const { chains, provider } = configureChains(
  [chain.mainnet,chain.goerli,chain.hardhat,chain.localhost],
  [
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY }),
    publicProvider()
  ]
);
const { connectors } = getDefaultWallets({
  appName: 'Invoices NFT',
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

// WAGMI

const App = () => {
  const [showsidebar, setShowsidebar] = useState(true)
  return (
    <div className=' min-h-screen bg-pr overflow-x-hidden'>
      <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
      <Router>
        {showsidebar && <Sidebar
          setShowsidebar={setShowsidebar}  showsidebar={showsidebar} />}
        <div className={showsidebar ? 'ml-20 lg:ml-60 relative' : "ml-0"}>
          <Topbar
            setShowsidebar={setShowsidebar}  showsidebar={showsidebar} 
             />
        </div>
        <div className={showsidebar ? 'ml-0 lg:ml-72 ' : "ml-0 "}>
          <Switch>
            <Route exact path="/">
              <Home/>
            </Route>
            <Route exact path="/purchase-document">
              <Purchase_document/>
            </Route>
            <Route exact path="/sales-document">
              <Sales_document/>
            </Route>
            <Route exact path="/new-document">
              <NewDocument/>
            </Route>
            <Route exact path="/resource-swap">
              <ResourceSwap/>
            </Route>
          </Switch>
        </div>
      </Router>
      </RainbowKitProvider>
      </WagmiConfig>
      
    </div>
  )
}

export default App
