import { React, useEffect, useState } from "react";
import { MdOpenInNew } from "react-icons/md";
import configData from "../utils/Config";
import { getAccount, useConnect } from "wagmi";
import { BigNumber, ethers } from "ethers/lib";

import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useNetwork,
} from "wagmi";
import OpenInvoices from "../components/OpenInvoices";
import CreatedByMeOpen from "../components/CreatedByMeOpen";
// import OpenInvoices from "./OpenInvoices";
// import ClosedInvoices from "./ClosedInvoices";

const Home = () => {
  const { connect } = useConnect();
  const { isConnected, address } = useAccount();
  const [openByMe, setOpenByMe] = useState(0);
  const [openToMe, setOpenToMe] = useState(0);
  const [closedToMe, setClosedToMe] = useState(0);
  const [closedByMe, setClosedByMe] = useState(0);
  const [moneyIncoming, setMoneyIncoming] = useState(0);
  const [moneyOutGoing, setMoneyOutGoing] = useState(0);
  const [totCreatedByMe, setTotCreatedByMe] = useState(0);
  const [totCreatedForMe, setTotCreatedForMe] = useState(0);
  const [ethPrice, setEthPrice] = useState(1);
  const { chain } = useNetwork();
  const dollarPrice = 1;
  const nftContractConfig = {
    addressOrName: configData.NFTContractAddress,
    contractInterface: configData.abi,
  };

  const STATUS_ENUM = { 0: "OPEN", 1: "CLOSED" };

  function getDollars(k) {
    return k / 10 ** 6;
  }

  const { data: ethPriceRes } = useContractRead({
    ...nftContractConfig,
    functionName: "getEthPrice",
    watch: false,
    onSuccess(data) {
      console.log("ETHPRICE", ethPrice);
      setEthPrice(Math.round(Number(ethPriceRes / 10 ** 8)));
    },
  });

  const { data: invCreatedByMe } = useContractRead({
    ...nftContractConfig,
    functionName: "getInvoicesCreatedByMe",
    args: [address],
    watch: true,
    onSuccess(data) {
      console.log("Invoices By me", data);
      let c = 0;
      let d = 0;
      let moneyIn = 0;

      // setTotCreatedByMe(data.length)
      data.map((k) => {
        let tot = k[12];
        if (k[5] == 0) {
          if (k[5] == 0) {
            c = c + 1;

            if (k[6] == 0) {
              //eth

              // let amt = Bignumber.from(k[7])
              // console.log("amt ",amt)

              moneyIn = moneyIn + ethers.utils.formatEther(k[7]) * ethPrice;
            } else {
              //usdc or usdt
              // let amt = k[7]

              moneyIn = moneyIn + getDollars(k[7]) * dollarPrice;
            }
          }
        } else {
          d = d + 1;
        }
      });
      setMoneyIncoming(moneyIn.toFixed(2));
      setOpenByMe(c);
      setClosedByMe(d);
    },
  });

  const { data: invCreatedForMe } = useContractRead({
    ...nftContractConfig,
    functionName: "getInvoicesForMe",
    args: [address],
    watch: true,
    onSuccess(data) {
      console.log("Invoices for me", data);
      setTotCreatedForMe(data.length);
      let c = 0;
      let d = 0;
      let moneyOut = 0;
      data.map((k) => {
        let tot = k[12];
        if (k[5] == 0) {
          c = c + 1;

          if (k[6] == 0) {
            //eth

            // let amt = Number()
            // console.log("amt ",amt)

            moneyOut = moneyOut + ethers.utils.formatEther(k[7]) * ethPrice;
          } else {
            //usdc or usdt
            // let amt = k[7]
            moneyOut = moneyOut + getDollars(k[7]) * dollarPrice;
          }
        } else {
          d = d + 1;
        }
      });
      setMoneyOutGoing(moneyOut.toFixed(2));
      setOpenToMe(c);
      setClosedToMe(d);
    },
  });

  return (
    <div className=" pl-2 pr-2  lg:pl-24 lg:pr-24">
      <div className=" w-full grid grid-cols-1 lg:grid-cols-3 gap-10">
        <div className=" rounded-md bg-hr w-full border-1 border-rahmen shadow-lg">
          <h1
            style={{ border: "1px solid #41454f" }}
            className=" text-white text-xl lg:text-3xl text-center px-2 py-4 w-4/5 mx-auto -mt-7 rounded-md shadow bg-tabelle"
          >
            Open Documents
          </h1>
          <div className=" mt-3 w-full flex items-center justify-between p-4">
            <div className=" flex flex-col items-center">
              <p className=" text-sm text-white">Purchase</p>
              <h1 className=" text-4xl text-gray-50 pt-2">{openToMe}</h1>
            </div>
            <div className=" flex flex-col items-center">
              <p className=" text-sm text-white">Sales</p>
              <h1 className=" text-4xl text-gray-50 pt-2">{openByMe}</h1>
            </div>
          </div>
        </div>
        <div className=" rounded-md bg-hr w-full border-1 border-rahmen shadow-lg">
          <h1
            style={{ border: "1px solid #41454f" }}
            className=" text-white text-xl lg:text-3xl text-center px-2 py-4 w-4/5 mx-auto -mt-7 rounded-md shadow bg-tabelle"
          >
            Closed Documents
          </h1>
          <div className=" mt-3 w-full flex items-center justify-between p-4">
            <div className=" flex flex-col items-center">
              <p className=" text-sm text-white">Purchase</p>
              <h1 className=" text-4xl text-gray-50 pt-2">{closedToMe}</h1>
            </div>
            <div className=" flex flex-col items-center">
              <p className=" text-sm text-white">Sales</p>
              <h1 className=" text-4xl text-gray-50 pt-2">{closedByMe}</h1>
            </div>
          </div>
        </div>
        <div className=" rounded-md bg-hr w-full border-1 border-rahmen shadow-lg">
          <h1
            style={{ border: "1px solid #41454f" }}
            className=" text-white text-xl lg:text-3xl text-center px-2 py-4 w-4/5 mx-auto -mt-7 rounded-md shadow bg-tabelle"
          >
            Open Sum
          </h1>
          <div className=" mt-3 w-full flex items-center justify-between p-4">
            <div className=" flex flex-col items-center">
              <p className=" text-sm text-white">Purchase</p>
              <h1 className=" text-4xl text-gray-50 pt-2">
                {Math.round(moneyOutGoing)}$
              </h1>
            </div>
            <div className=" flex flex-col items-center">
              <p className=" text-sm text-white">Sales</p>
              <h1 className=" text-4xl text-gray-50 pt-2">
                {Math.round(moneyIncoming)}$
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className=" w-full mt-8">
        <h1 className=" text-3xl  text-white ">Open Purchase Documents</h1>
        <OpenInvoices ethPrice={ethPrice} />
      </div>
      <div className=" w-full mt-8">
        <h1 className=" text-3xl  text-white ">Open Sales Documents</h1>
        <CreatedByMeOpen ethPrice={ethPrice} />
      </div>
    </div>
  );
};

export default Home;
