import { Button } from "@mui/material";
import Card from "react-bootstrap/Card";
import configData from "../utils/Config";
import moment from "moment";
import { MdOpenInNew } from "react-icons/md";
import { Stack } from "@mui/material";

import { BigNumber, ethers } from "ethers/lib";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";

const STATUS_ENUM = { 0: "OPEN", 1: "CLOSED" };
const PAYMENT_MODE_ENUM = { 0: "ETH", 1: "USDT", 2: "USDC" };
const DECIMALS_ENUM = { 0: 18, 1: 6, 2: 6 };

// const nftContractConfig = {
//   addressOrName: configData.NFTContractAddress,
//   contractInterface: configData.abi,
// };

// const USDCContractConfig = {
//   addressOrName: configData.USDCAddress,
//   contractInterface: configData.erc20ABI,
// };

// const USDTContractConfig = {
//   addressOrName: configData.USDTAddress,
//   contractInterface: configData.erc20ABI,
// };

function getTime(t) {
  var time = moment(t.toNumber() * 1000).format("MM-DD-YYYY");
  return time;
}

function formatAddress(a) {
  var v = a.substring(0, 6) + "..." + a.substring(a.length - 4);
  return v;
}

function ClosedCard(props) {
  const [
    tokenId,
    to,
    from,
    openedOn,
    closedOn,
    status,
    paymentMode,
    amount,
    invoiceUrl,
    dummyId,
    cut,
  ] = [
    props.invoice[0],
    props.invoice[1],
    props.invoice[2],
    props.invoice[3],
    props.invoice[4],
    props.invoice[5],
    props.invoice[6],
    props.invoice[7],
    props.invoice[8],
    props.invoice[9],
    props.invoice[10],
  ];
  const { address, isConnected } = useAccount();
  function formatAmount() {
    return (amount / 10 ** DECIMALS_ENUM[paymentMode])
      .toFixed(10)
      .toString()
      .replace(/(\.[0-9]*[1-9])0+$|\.0*$/, "$1");
  }

  function getFiat() {
    if (paymentMode == 0) {
      return ethers.utils.formatEther(amount.toString()) * props.ethPrice;
    } else {
      return amount / 10 ** 6;
    }
  }

  function getIpfsUrl() {
    const convertedGatewayUrl = configData.desiredGatewayPrefix + invoiceUrl;

    return convertedGatewayUrl;
  }
  return (
    <tr style={{ background: "#242834" }}>
      <td className="px-6 py-3 whitespace-nowrap ">
        <p>#{props.len - props.index}</p>
      </td>
      <td className="px-6 py-3 whitespace-nowrap">
        <p>-</p>
      </td>
      <td className="px-6 py-3 whitespace-nowrap">
        <p>{formatAddress(from)}</p>
      </td>
      <td className="px-6 py-3 whitespace-nowrap">
        <p>{formatAmount()}</p>
      </td>
      <td className="px-6 py-3 whitespace-nowrap">
        <p>{PAYMENT_MODE_ENUM[paymentMode]}</p>
      </td>
      <td className="px-6 py-3 whitespace-nowrap">
        <p>{getFiat().toFixed(2)}$</p>
      </td>
      <td className="px-6 py-3 whitespace-nowrap">
        <p>{getTime(openedOn)}</p>
      </td>
      <td className="px-6 py-3 text-blue-500 whitespace-nowrap">
        <button
          style={{ cursor: "auto" }}
          className="px-2 py-0,5 flex items-center gap-2 text-white bg-nr rounded-md border-1 border-rahmen shadow-lg"
        >
          {" "}
          {status ? "Paid" : "Pay"}{" "}
        </button>
      </td>
      <td className="px-6 py-3 text-blue-500 cursor-pointer whitespace-nowrap">
        <a
          target="_blank"
          style={{ textDecoration: "none" }}
          href={getIpfsUrl()}
          download
        >
          <MdOpenInNew />
        </a>
      </td>
    </tr>
  );
}

export default ClosedCard;
