import { AiFillPlusCircle } from "react-icons/ai";
import { VscThreeBars } from "react-icons/vsc";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { DarkMode } from "@mui/icons-material";
import {  darkTheme } from '@rainbow-me/rainbowkit';

const Topbar = ({setShowsidebar,
  showsidebar}) => {
  return (
    <div className=" w-full flex items-center justify-between pr-10">
  {showsidebar === false &&     <div className=" flex items-center gap-3 pl-4">
        <VscThreeBars onClick={()=>setShowsidebar(!showsidebar)} className="w-8 h-8 text-white cursor-pointer "/>
        <Link to="/">
        <img className=" hidden lg:block  cursor-pointer lg:w-48 object-contain lg:ml-3  w-20"  src={logo} alt="" />
        </Link>
      </div>}

      <div className=" h-40"></div>
        <AiFillPlusCircle className=" w-5 h-5"/>
        <ConnectButton  theme={darkTheme({
      accentColor: '#7b3fe4',
      accentColorForeground: 'white',
      borderRadius: 'medium', 
    })} accountStatus="address" chainStatus={"name"} showBalance={false}/>      
      </div>
  );
};

export default Topbar;
