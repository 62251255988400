import React, { useState, useEffect } from "react";
import { FaMinusCircle, FaSave } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import { MdEdit, MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  Checkbox,
} from "@mui/material";
import { useAccount } from "wagmi";
import { BigNumber } from "ethers";
import IPFSGatewayTools from "@pinata/ipfs-gateway-tools/dist/browser";

import axios from "axios";
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { useContract, useProvider, useSigner } from "wagmi";

import configData from "../utils/Config";
import { ethers } from "ethers";
import { MenuItem, Paper, TextField, Typography } from "@mui/material";
import { Stack, Box } from "@mui/material";

const mintStatusJson = {
  idle: "Create",
  loading: "Processing",
  success: "Create",
  error: "error",
};
const gatewayTools = new IPFSGatewayTools();

const nftContractConfig = {
  addressOrName: configData.NFTContractAddress,
  contractInterface: configData.abi,
};

function validateAddress(a) {
  return ethers.utils.isAddress(a.trim());
}

const NewDocument = () => {
  const [dateSelected, setDateSelected] = useState("");
  const [dateSelected2, setDateSelected2] = useState("");
  const [dateSelected3, setDateSelected3] = useState("");
  const [type, setType] = useState("");
  const [quantity, setquantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [desc, setDesc] = useState("");
  const [tax, setTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(0);

  const [mainData, setMainData] = useState([]);
  const [demoMain, setDemoMain] = useState(0);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const [modeOfPayment, setModeOfPayment] = useState("ETH");
  const modeIndex = { ETH: 0, USDT: 1, USDC: 2 };
  const decimalIndex = { ETH: 18, USDT: 6, USDC: 6 };
  const [customerWallet, setcustomerWallet] = useState("");
  const [alias, setAlias] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [customerRefNo, setCustomerRefNo] = useState("");
  const [owner, setOwner] = useState("");
  const [remarks, setRemarks] = useState("");
  const [amount, setAmount] = useState(1);
  const [pdfUrl, setPdfUrl] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [mintBtnStatus, setMintBtnStatus] = useState(false);
  const [lastTxn, setLastTxn] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [temp, setTemp] = useState("");
  const [hash, setHash] = useState(null);
  const [checked, setChecked] = useState(true);
  const [processing, setProcessing] = useState(false);
  let ImgHash = "";
  let PDFHash = "";
  let fileImg = null;
  let filePDF = null;
  const { address, isConnected } = useAccount();
  let iTry = 0;
  let pTry = 0;
  let count = 1;

  useEffect(() => {
    setTotal(quantity * price * (1 - discount / 100));
  }, [tax, price, quantity]);

  const { data: documentNumber } = useContractRead({
    ...nftContractConfig,
    functionName: "myTokens",
    args: [address],
    watch: true,
  });

  const insertItem = (id) => {
    let demDisc = {
      id: id,
      type: type,
      quantity: quantity,
      desc: desc,
      tax: tax,
      discount: discount,
      price: price,
      total: total,
    };
    setMainData([...mainData, demDisc]);
    setType("");
    setDiscount(0);
    setquantity(0);
    setDesc("");
    setTax(0);
    setPrice(0);
    setTotal(0);
    setDemoMain(-1);
  };

  const isDirty = type || discount || quantity || desc || tax || price || total;

  const editInsert = (ind, id) => {
    let demDisc = {
      id: id,
      type: type,
      quantity: quantity,
      desc: desc,
      tax: tax,
      discount: discount,
      price: price,
      total: total,
    };
    let demMain = mainData;
    demMain[ind] = demDisc;
    setMainData([...demMain]);
    setType("");
    setDiscount(0);
    setquantity(0);
    setDesc("");
    setTax(0);
    setPrice(0);
    setTotal(0);
    setDemoMain(-1);
  };
  const editItem = (item) => {
    setDemoMain(item?.id);
    setType(item?.type || "");
    setquantity(item?.quantity);
    setDesc(item?.desc);
    setTax(item?.tax);
    setDiscount(item?.discount);
    setPrice(item?.price);
    setTotal(item?.total);
  };
  const deleteLast = () => {
    setType("");
    setquantity(0);
    setDesc("");
    setTax(0);
    setDiscount(0);
    setPrice(0);
    setTotal(0);
    setDemoMain(-1);
  };

  useEffect(() => {
    if (dateSelected2 === "" && dateSelected3 === "") {
      setDateSelected2(dateSelected);
      setDateSelected3(dateSelected);
    }
  }, [dateSelected, dateSelected2, dateSelected3]);

  const deleteOne = (id) => {
    let demArr = mainData.filter((item) => item.id !== id);
    setMainData([...demArr]);
    if (demArr.length === 0) {
      setDemoMain(0);
    }
  };
  const [currency, setCurrency] = useState("ETH");
  const [netTotal, setNetTotal] = useState(0);
  const [allTotal, setAllTotal] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [allDiscount, setAllDiscount] = useState(0);

  useEffect(() => {
    let allTotal = 0;
    let netTotal = 0;
    let totalVal = 0;
    let totalDiscount = 0;
    let totalTax = 0;

    // console.log({ mainData });
    mainData.forEach((item) => {
      console.log({ item });
      totalVal = totalVal + item?.total;
      totalDiscount =
        totalDiscount + (item.quantity * item.price * item.discount) / 100;
      netTotal = netTotal + item.price * item.quantity;

      totalTax =
        totalTax +
        item.quantity *
          item.price *
          (1 - item.discount / 100) *
          (item.tax / 100);
    });
    setAllTotal(totalVal);
    setNetTotal(netTotal);
    setAllDiscount(totalDiscount);
    setTotalTax(totalTax);
    setAllTotal(netTotal + totalTax - totalDiscount);
  }, [discount, mainData, price, quantity]);

  //smart contract integration start
  const { data: supply } = useContractRead({
    ...nftContractConfig,
    functionName: "totalSupply",
    watch: true,
  });

  const provider = useProvider();
  const { data: signer, isError, isLoading } = useSigner();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  function getEtherscanLink(l) {
    return configData.etherscanUrl + l;
  }

  const contract = useContract({
    addressOrName: configData.NFTContractAddress,
    contractInterface: configData.abi,
    signerOrProvider: signer,
  });

  //address _to, uint _paymentMode, uint _amount, string memory _invoiceUrl, string[] memory _meta {string aliass,string refNo,string serviceDate,string imageUrl}

  async function createInvoice(url1, url2) {
    setProcessing(true);
    console.log("PAY ASYNC ");
    console.log("MODE ", modeIndex[currency]);
    let _amt = BigNumber.from(
      Math.round((allTotal - specialDiscount) * 10 ** 6),
    ).mul(BigNumber.from(10).pow(decimalIndex[currency] - 6));
    console.log("BIGNUM ANS ", _amt);
    console.log(
      "AMT ",
      String(
        Math.round((allTotal - specialDiscount) * 10 ** decimalIndex[currency]),
      ),
    );
    try {
      let b = await contract.createInvoice(
        customerWallet,
        modeIndex[currency],
        _amt,
        url1,
        [alias, customerRefNo.toString(), dateSelected2.toString(), url2],
      );
      if (!b) {
        setProcessing(false);
      }
      console.log("TXN HASH is  ", b);
      setLastTxn(getEtherscanLink(b.hash));
      setHash(b);
    } catch (err) {
      console.log("ERROR createinvoice ", err);
      setProcessing(false);
    }

    console.log("LAST TXN ", lastTxn);
    setMintBtnStatus(true);
  }

  const {
    data: reciept,
    isSuccess: txSuccess,
    error: txError,
    status: mintStatus,
  } = useWaitForTransaction({
    hash: hash?.hash,
    onSuccess(data) {
      console.log("Success TX", data);
      console.log("LOGS ", data.logs);
      setOpen(true);
      setSuccessMsg("Mint Successful");
      setMintBtnStatus(false);
      setErrorMsg(null);

      ////////////////////////

      let iface = new ethers.utils.Interface([
        "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)",
      ]);
      const transferFragment = iface.getEvent("Transfer");
      for (let i = 0; i < data.logs.length; i++) {
        //for(let j=0;j<receipt.logs[i].topics.length;j++){
        if (iface.getEventTopic(transferFragment) == data.logs[i].topics[0]) {
          let result = iface.decodeEventLog(
            transferFragment,
            data.logs[i].data,
            data.logs[i].topics,
          );
          console.log("RESULT ", result);
          console.log("FROM ", result.from);
          console.log("TO ", result.to);

          let tok = Number(result.tokenId);
          console.log("MINTED TOKEN ID ", tok);

          setSuccessMsg("Mint Successful");
          setErrorMsg(null);
        }
        setProcessing(false);
      }

      ////////////
    },
    onError(err) {
      setProcessing(false);
      console.log("MINT ERROR", err);
      setErrorMsg(err);
    },
  });

  const isMinted = txSuccess;
  //smart contract integration end

  useEffect(() => {
    console.log("ADDRESS ", address);
    //console.log(totalSupplyData.toNumber())
    window.ethereum.on("accountsChanged", () => {
      window.location.reload();
    });
  });

  const [specialDiscount, setSpecialDiscount] = useState(0);
  const controlDiscount = (dis) => {
    setSpecialDiscount((allTotal * dis) / 100);
  };

  const validateAndMint = async () => {
    setProcessing(true);
    console.log("ALIAS ", alias);
    console.log("DATE ", dateSelected);
    console.log("MAIN DATA ", mainData);

    console.log("SUBMIT FORM");
    setErrorMsg(null);
    setSuccessMsg(null);

    setErrorMsg(null);
    setMintBtnStatus(true);

    console.log("PDF HASH 2", PDFHash);
    console.log("Img Hash 2", ImgHash);
    setTemp(temp);
    console.log("Temp ", temp);
    if (ImgHash.length == 0) {
      // if(filePDF.size>configData.maxFileSize){
      //   setErrorMsg("Max upload "+configData.maxFileSize/1000000+" MB")
      //   setMintBtnStatus(false)
      //   return
      // }
      setErrorMsg("Something went wrong please try again");
      setProcessing(false);
      // if(iTry==0){
      //   console.log("RETRYING")
      //   await validateAndMint()
      //   iTry=iTry+1;
      //   return;
      // }
      console.log("Failed Upload to ipfs");
      setMintBtnStatus(false);
      return;
    }
    if (PDFHash.length == 0) {
      // if(filePDF.size>configData.maxFileSize){
      //   setErrorMsg("Max upload "+configData.maxFileSize/1000000+" MB")
      //   setMintBtnStatus(false)
      //   return
      // }
      setErrorMsg("Something went wrong please try again");
      console.log("Failed Upload to ipfs");
      setMintBtnStatus(false);
      setProcessing(false);
      return;
    }

    createInvoice?.(PDFHash, ImgHash);
  };
  const sendPDFFileToIPFS = async (_file, _response) => {
    //await getFileFromBase64()
    //TODO : CHECK FILE SIZE HERE

    // if(filePDF.size>configData.maxFileSize){
    //   console.log("MAX UPLOAD")
    //   setErrorMsg("Max upload "+configData.maxFileSize/1000000+" MB")
    //   setMintBtnStatus(false)
    //   return
    // }

    if (_file) {
      try {
        setIsFileUploading(true);
        const formData = new FormData();
        formData.append("file", _file);

        const resFile = await axios({
          method: "post",
          url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
          data: formData,
          headers: {
            pinata_api_key: `${process.env.REACT_APP_PINATA_API_KEY}`,
            pinata_secret_api_key: `${process.env.REACT_APP_PINATA_API_SECRET}`,
            "Content-Type": "multipart/form-data",
          },
        });

        PDFHash = resFile.data.IpfsHash;
        let PDFHashUrl = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`;
        console.log("PDF Img Hash ", PDFHash);
        setPdfUrl(PDFHashUrl);
        console.log("PDF URL ", pdfUrl);
        await getIMGFileFromBase64(_response);

        //Take a look at your Pinata Pinned section, you will see a new file added to you list.
      } catch (error) {
        setIsFileUploading(false);
        setProcessing(false);
        setErrorMsg("Error sending File to IPFS: ");
        console.log("Error sending File to IPFS: ");
        console.log(error);
        setPdfUrl("");
      }

      setIsFileUploading(false);
    } else {
      console.log("PDF FILE UNAVAILABLE");
    }
  };

  const sendIMGFileToIPFS = async (_file) => {
    //await getFileFromBase64()
    //TODO : CHECK FILE SIZE HERE

    // if(filePDF.size>configData.maxFileSize){
    //   console.log("MAX UPLOAD")
    //   setErrorMsg("Max upload "+configData.maxFileSize/1000000+" MB")
    //   setMintBtnStatus(false)
    //   return
    // }

    if (_file) {
      try {
        setIsFileUploading(true);
        const formData = new FormData();
        formData.append("file", _file);

        const resFile = await axios({
          method: "post",
          url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
          data: formData,
          headers: {
            pinata_api_key: `${process.env.REACT_APP_PINATA_API_KEY}`,
            pinata_secret_api_key: `${process.env.REACT_APP_PINATA_API_SECRET}`,
            "Content-Type": "multipart/form-data",
          },
        });

        ImgHash = resFile.data.IpfsHash;
        let ImgHashUrl = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`;
        console.log("IMG Img Hash ", ImgHash);
        setImgUrl(ImgHashUrl);
        console.log("IMG URL ", imgUrl);
        await validateAndMint();

        //Take a look at your Pinata Pinned section, you will see a new file added to you list.
      } catch (error) {
        setIsFileUploading(false);
        setProcessing(false);
        setErrorMsg("Error sending File to IPFS: ");
        console.log("Error sending File to IPFS: ");
        console.log(error);
        setImgUrl("");
      }

      setIsFileUploading(false);
    } else {
      console.log("IMG FILE UNAVAILABLE");
    }
  };

  const getPDFFileFromBase64 = async (_response) => {
    const url = "data:application/pdf;base64," + _response.pdf.fileContents;
    let file = null;
    fetch(url)
      .then((res) => res.blob())
      .then(async (blob) => {
        file = new File([blob], _response.pdf.fileDownloadName, {
          type: "application/pdf",
        });
        console.log("PDF FILE ", file);
        filePDF = file;
        await sendPDFFileToIPFS(file, _response);
      });
  };
  const getIMGFileFromBase64 = async (_response) => {
    const url = "data:application/jpg;base64," + _response.image.fileContents;
    let file = null;
    fetch(url)
      .then((res) => res.blob())
      .then(async (blob) => {
        file = new File([blob], _response.image.fileDownloadName, {
          type: "application/jpg",
        });
        console.log("IMG FILE ", file);
        fileImg = file;
        await sendIMGFileToIPFS(file);
      });
  };

  const testPostman = async () => {
    var myHeaders = new Headers();
    myHeaders.append("accept", "*/*");
    myHeaders.append("Authorization", "Basic b2JpdXM6cGFzc3dvcmQ=");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      customerWallet: "string",
      documentNo: 0,
      documentDate: "2022-11-01T04:52:05.358Z",
      alias: "string",
      contactPerson: "string",
      customerRefNo: 0,
      serviceDate: "2022-11-01T04:52:05.358Z",
      dueDate: "2022-11-01T04:52:05.358Z",
      owner: "string",
      remarks: "string",
      paymentOption: "string",
      totalTax: 0,
      totalDiscount: 0,
      subTotal: 0,
      total: 0,
      status: "string",
      items: [
        {
          lineNumber: 0,
          itemNo: 0,
          description: "string",
          quantity: 0,
          unitPrice: 0,
          discount: 0,
          discountSum: 0,
          type: "string",
          tax: 0,
          taxSum: 0,
          totalSum: 0,
          subTotal: 0,
        },
      ],
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.digital-invoice.io/api/invoice/generate", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log("RESULT PDF", JSON.parse(result).pdf.fileContents);
        console.log("RESULT IMG", JSON.parse(result).image.fileContents);
      })
      .catch((error) => console.log("error", error));
  };

  const createAndUploadDocument = async (e) => {
    // 0x8E4A203A9D1c647655a802F903F7fD4a6B3632f7
    e.preventDefault();

    isDirty && insertItem(mainData?.length + 1);

    setProcessing(true);
    console.log("CREATE AND UPLOAD DOC");

    if (!validateAddress(customerWallet)) {
      setErrorMsg("Invalid To Address");
      console.log("Invalid To Address");
      setProcessing(false);
      return;
    }
    if (customerWallet == address) {
      setErrorMsg("Cannot mint to yourself");
      console.log("Cannot mint to yourself");
      setProcessing(false);
      return;
    }
    if (mainData.length == 0) {
      setErrorMsg("No items found");
      console.log("No items found");
      setProcessing(false);
      return;
    }
    var itemsArray = [];
    let mainData2 = mainData;
    mainData2.map((k) => {
      itemsArray.push({
        lineNumber: 0,
        itemNo: 0,
        description: k.desc,
        quantity: k.quantity,
        unitPrice: k.price,
        discount: k.discount,
        discountSum: k.discount,
        type: k.type,
        tax: k.tax,
        taxSum: 0,
        totalSum: 0,
        subTotal: k.total,
      });
    });

    var myHeaders = new Headers();
    myHeaders.append("accept", "*/*");
    myHeaders.append("Authorization", process.env.REACT_APP_API_AUTH);
    myHeaders.append("Content-Type", "application/json");
    let doc = 0;
    if (documentNumber) {
      doc = Number(documentNumber) + 1;
    } else {
      doc = 1;
    }
    var raw = JSON.stringify({
      customerWallet: customerWallet,
      documentNo: doc,
      documentDate: dateSelected,
      alias: alias,
      contactPerson: contactPerson,
      customerRefNo: customerRefNo,
      serviceDate: dateSelected2, //"2022-10-28T12:56:00.519Z"
      dueDate: dateSelected3,
      owner: owner,
      remarks: remarks,
      paymentOption: currency,
      totalTax: totalTax,
      totalDiscount: allDiscount,
      subTotal: netTotal,
      total: allTotal,
      status: "Open",
      items: itemsArray,
    });

    console.log("RAW ", raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.digital-invoice.io/api/invoice/generate", requestOptions)
      .then((response) => response.text())
      .then(async (result) => {
        console.log(result);
        console.log("RESULT PDF", JSON.parse(result).pdf.fileContents);
        console.log("RESULT IMG", JSON.parse(result).image.fileContents);
        await getPDFFileFromBase64(JSON.parse(result));
      })
      .catch((error) => {
        setErrorMsg("Failed Upload: try again later");
        console.log("error", error);
        setProcessing(false);
      });
  };

  const handleCustomerWalletChange = (event) => {
    setcustomerWallet(event.target.value.trim());
    console.log("VALID ADDR ", validateAddress(event.target.value));
  };

  const commaHandler = (val, setter) => {
    if (val.includes(",")) {
      // if (
      //   val.split(".").filter((v) => v === "").length > 0 ||
      //   val.split(",").filter((v) => v === "").length > 1
      // ) {
      //   setter(val);
      //   return;
      // }

      const replaced = val.replace(",", ".");
      console.log({ replaced });
      if ([...replaced].filter((v) => v === ".").length > 1) {
        // const dd = [...replaced]
        //   .map((v, i) => {
        //     if (v !== ".") return v;

        //     if (replaced[i + 1] === ".") return "";

        //     return "";
        //   })
        //   .join("");

        return;
      } else {
        setter(replaced);
        return;
      }
    }

    if (isNaN(val)) return;

    setter(val);
  };

  return (
    <div className="pb-10">
      {/* <button onClick={testPostman} style={{color:"white"}}>Test</button> */}
      <form onSubmit={createAndUploadDocument}>
        <div className=" pl-3 pr-3 lg:pl-16 lg:pr-24">
          {/* {isFileUploading?
      <Stack direction={"row"} spacing={2} justifyContent={"center"} alignItems={"center"}>
        <CircularProgress color="secondary" />
        <p>Uploading file to IPFS</p>
      </Stack>
      :
      null} */}
          {txError && (
            <p style={{ marginTop: 24, color: "#FF6257" }}>
              Error: {txError.message.split("(")[0]}
            </p>
          )}
          {errorMsg && (
            <p style={{ marginTop: 24, color: "#FF6257" }}>Error: {errorMsg}</p>
          )}

          {successMsg ? (
            <p style={{ marginTop: 24, color: "green" }}>
              Success: {successMsg}
            </p>
          ) : null}

          {/* <Snackbar open={showAlert} autoHideDuration={6000} onClose={()=>{setShowAlert(null)}}>
    <Alert onClose={()=>{setShowAlert(null)}} severity="error" sx={{ width: '100%' }}>
      {showAlert}
    </Alert>
  </Snackbar> */}
          <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={() => {
              setOpen(false);
              window.location.reload();
            }}
          >
            <Alert
              onClose={() => {
                setOpen(false);
              }}
              severity="success"
              sx={{ width: "100%" }}
            >
              Successfully Created
            </Alert>
          </Snackbar>
          {/* {showAlert?<Alert severity='error' onClose={() => {setShowAlert(null)}}> <AlertTitle>Error</AlertTitle> <br/>{showAlert}</Alert>:null} */}
          {/* {open ? <ConfettiComponent /> : null} */}
          <h1 className=" text-center text-xl lg:text-4xl text-white -mt-10">
            Create <span className=" text-nr">New</span> Document
          </h1>
          <div className=" w-full grid grid-cols-1 lg:grid-cols-2 gap-10 mt-3 lg:px-10">
            <div className=" bg-sr w-full rounded-md p-3 border-1 border-rahmen shadow-lg">
              <div className=" flex items-center justify-between gap-3">
                <p className=" text-white">Customer Wallet</p>
                <input
                  type="text"
                  required
                  placeholder="Customer Wallet"
                  value={customerWallet}
                  onChange={handleCustomerWalletChange}
                  className=" bg-pr border-none outline-none px-2 py-2 text-white rounded-md"
                />
              </div>
              <div className=" flex items-center justify-between gap-3 mt-2">
                <p className=" text-white">Alias</p>
                <input
                  type="text"
                  placeholder="Alias"
                  value={alias}
                  onChange={(e) => setAlias(e.target.value)}
                  className=" bg-pr border-none outline-none px-2 py-2 text-white rounded-md"
                  required
                />
              </div>
              <div className=" flex items-center justify-between gap-3 mt-2">
                <p className=" text-white">Contact Person</p>
                <input
                  type="text"
                  required
                  placeholder="Contact Person"
                  value={contactPerson}
                  onChange={(e) => setContactPerson(e.target.value)}
                  className=" bg-pr border-none outline-none px-2 py-2 text-white rounded-md"
                />
              </div>
              <div className=" flex items-center justify-between gap-3 mt-2">
                <p className=" text-white">Customer Ref. No.</p>
                <input
                  type="text"
                  required
                  value={customerRefNo}
                  onChange={(e) => setCustomerRefNo(e.target.value)}
                  placeholder="Customer Ref. No."
                  className=" bg-pr border-none outline-none px-2 py-2 text-white rounded-md"
                />
              </div>
            </div>
            <div className=" bg-sr w-full rounded-md p-3 border-1 border-rahmen shadow-lg">
              <div className=" flex items-center justify-between gap-3 ">
                <p className=" text-white">Document Number</p>
                <input
                  type="text"
                  disabled
                  value={documentNumber ? Number(documentNumber) + 1 : 1}
                  placeholder="003"
                  className=" bg-hr w-52 border-none outline-none px-2 py-2 text-white"
                />
              </div>
              <div className=" flex items-center justify-between gap-3 mt-2">
                <p className=" text-white">Status</p>
                <input
                  type="text"
                  disabled
                  placeholder="Open"
                  className=" bg-hr w-52 border-none outline-none px-2 py-2 text-white"
                />
              </div>
              <div className=" flex items-center justify-between gap-3 mt-2">
                <p className=" text-white">Document Date</p>
                <input
                  required
                  value={dateSelected}
                  onChange={(e) => setDateSelected(e.target.value)}
                  type="date"
                  className=" w-52 bg-pr border-none outline-none px-2 py-2 text-white rounded-md"
                />
              </div>
              <div className=" flex items-center justify-between gap-3 mt-2">
                <p className=" text-white">Service Date</p>
                <input
                  value={dateSelected2}
                  onChange={(e) => setDateSelected2(e.target.value)}
                  type="date"
                  required
                  className=" w-52 bg-pr border-none outline-none px-2 py-2 text-white rounded-md"
                />
              </div>
              <div className=" flex items-center justify-between gap-3 mt-2">
                <p className=" text-white">Due Date</p>
                <input
                  required
                  value={dateSelected3}
                  onChange={(e) => setDateSelected3(e.target.value)}
                  type="date"
                  className=" w-52 bg-pr border-none outline-none px-2 py-2 text-white rounded-md"
                />
              </div>
            </div>
          </div>
          <div className=" overflow-x-auto  mt-7 px-2 ">
            <div className="py-3 inline-block min-w-full sm:px-6 lg:px-8 ">
              <div className=" overflow-auto lg:overflow-y-scroll h-52  rounded-lg bg-tabelle pb-3 border-1 border-rahmen shadow-lg">
                <table className="min-w-full">
                  <thead className="__sticky_thead">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                      >
                        No.
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                      >
                        Article
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                      >
                        Item Desc
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                      >
                        Quantity
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                      >
                        Price
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                      >
                        Discount%
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                      >
                        Tax%
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider whitespace-nowrap"
                      >
                        Net Total w/ disc.
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className=" text-gray-300 divide-y border-1 border-tabellenlinerahmen">
                    <>
                      {mainData.map((item, ind) => (
                        <>
                          {demoMain === item?.id ? (
                            <tr style={{ background: "#242834" }}>
                              <td className="px-6 py-3 whitespace-nowrap">
                                <input
                                  type="text"
                                  placeholder="1"
                                  value={ind + 1}
                                  disabled
                                  className=" bg-tabellenzeilen w-11 border-none outline-none px-1 rounded-md py-0 text-white"
                                />
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap">
                                <input
                                  placeholder="Article"
                                  value={type}
                                  type="text"
                                  onChange={(e) => setType(e.target.value)}
                                  className=" bg-tabellenzeilen  w-24 border outline-none px-2 rounded-md py-0 text-white"
                                />
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap">
                                <input
                                  type="text"
                                  value={desc}
                                  onChange={(e) => setDesc(e.target.value)}
                                  placeholder="Item description"
                                  className=" bg-tabellenzeilen  border w-52 outline-none px-2 rounded-md py-0 text-white"
                                />
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap">
                                <input
                                  type="number"
                                  value={quantity || ""}
                                  onChange={(e) =>
                                    commaHandler(e.target.value, setquantity)
                                  }
                                  placeholder="0"
                                  className=" bg-tabellenzeilen w-16 border outline-none px-1 rounded-md py-0 text-white"
                                />
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap">
                                <input
                                  type="number"
                                  value={price}
                                  placeholder="0"
                                  onChange={(e) => setPrice(e.target.value)}
                                  className=" bg-tabellenzeilen w-16 border outline-none px-1 rounded-md py-0 text-white"
                                />
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap">
                                <div className="w-fit relative">
                                  <input
                                    type="text"
                                    placeholder="0"
                                    value={discount}
                                    onChange={(e) =>
                                      commaHandler(e.target.value, setDiscount)
                                    }
                                    className=" bg-tabellenzeilen w-16 border outline-none pl-1 pr-6 rounded-md py-0 text-white"
                                  />
                                  <span className="absolute top-0 right-0 flex items-center pr-1.5 text-sm h-full">
                                    %
                                  </span>
                                </div>
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap">
                                <div className="w-fit relative">
                                  <input
                                    type="text"
                                    placeholder="0"
                                    value={tax || ""}
                                    onChange={(e) =>
                                      commaHandler(e.target.value, setTax)
                                    }
                                    className=" bg-tabellenzeilen w-16 border outline-none px-1 rounded-md py-0 text-white"
                                  />
                                  <span className="absolute top-0 right-0 flex items-center pr-1.5 text-sm h-full">
                                    %
                                  </span>
                                </div>
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap">
                                {currency === "ETH" ? (
                                  <p>{total.toFixed(6)}</p>
                                ) : (
                                  <p>{total.toFixed(2)}</p>
                                )}
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap">
                                <div className=" flex items-center gap-2">
                                  <button
                                    type="button"
                                    onClick={() => editInsert(ind, item?.id)}
                                    className=" px-2 py-2 flex items-center gap-2 text-white bg-nr rounded-md border-1 border-rahmen shadow-lg hover:bg-blue-500"
                                  >
                                    <FaSave />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr style={{ background: "#242834" }}>
                              <td className="px-6 py-3 whitespace-nowrap">
                                <input
                                  type="text"
                                  value={ind + 1}
                                  disabled
                                  className=" bg-tabellenzeilen w-11 border-none outline-none px-1 rounded-md py-0 text-white"
                                />
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap">
                                <input
                                  type="text"
                                  placeholder="Article"
                                  disabled
                                  value={item?.type}
                                  className=" bg-tabellenzeilen  w-24 border-none outline-none px-2 rounded-md py-0 text-white"
                                />
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap">
                                <input
                                  type="text"
                                  disabled
                                  placeholder="Item description"
                                  value={item?.desc}
                                  className=" bg-tabellenzeilen  border-none w-52 outline-none px-2 rounded-md py-0 text-white"
                                />
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap">
                                <input
                                  type="number"
                                  placeholder="0"
                                  disabled
                                  value={item?.quantity}
                                  className=" bg-tabellenzeilen w-16 border-none outline-none px-1 rounded-md py-0 text-white"
                                />
                              </td>
                              <td
                                className="px-6 py-3 whitespace-nowrap"
                                style={{ minWidth: "150px" }}
                              >
                                <input
                                  type="number"
                                  placeholder="0"
                                  disabled
                                  value={
                                    currency === "ETH"
                                      ? Number(item?.price).toFixed(6)
                                      : Number(item?.price).toFixed(2)
                                  }
                                  className=" bg-tabellenzeilen w-full border-none outline-none px-1 rounded-md py-0 text-white"
                                />
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap">
                                <input
                                  type="number"
                                  placeholder="0"
                                  disabled
                                  value={item.discount}
                                  className=" bg-tabellenzeilen w-16 border-none outline-none px-1 rounded-md py-0 text-white"
                                />
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap">
                                <input
                                  type="number"
                                  placeholder="0%"
                                  disabled
                                  value={item?.tax}
                                  className=" bg-tabellenzeilen w-16 border-none outline-none px-1 rounded-md py-0 text-white"
                                />
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap">
                                {currency === "ETH" ? (
                                  <p>{item?.total.toFixed(6)}</p>
                                ) : (
                                  <p>{item?.total.toFixed(2)}</p>
                                )}
                              </td>
                              <td className="px-6 py-3 whitespace-nowrap">
                                <div className=" flex items-center gap-2">
                                  <button
                                    type="button"
                                    onClick={() => editItem(item)}
                                    className=" px-2 py-2 flex items-center gap-2 text-white bg-nr rounded-md border-1 border-rahmen shadow-lg hover:bg-blue-500"
                                  >
                                    <MdEdit />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => setDemoMain(0)}
                                    className=" px-2 py-2 flex items-center gap-2 text-white bg-nr rounded-md border-1 border-rahmen shadow-lg hover:bg-blue-500"
                                  >
                                    <AiOutlinePlus />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => deleteOne(item?.id)}
                                    className=" px-2 py-2 flex items-center gap-2 text-white bg-nr rounded-md border-1 border-rahmen shadow-lg hover:bg-blue-500"
                                  >
                                    <MdDeleteOutline className=" w-4 h-4" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      ))}
                    </>

                    {demoMain === 0 && (
                      <tr style={{ background: "#242834" }}>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <input
                            type="text"
                            placeholder={mainData?.length + 1}
                            disabled
                            className=" bg-tabellenzeilen w-11 border-none outline-none px-1 rounded-md py-0 text-white"
                          />
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <input
                            type="text"
                            placeholder="Article"
                            onChange={(e) => setType(e.target.value)}
                            className=" bg-tabellenzeilen  w-24 border outline-none px-2 rounded-md py-0 text-white"
                          />
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <input
                            type="text"
                            onChange={(e) => setDesc(e.target.value)}
                            placeholder="Item description"
                            className=" bg-tabellenzeilen  border w-52 outline-none px-2 rounded-md py-0 text-white"
                          />
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <input
                            type="text"
                            placeholder="0"
                            value={quantity || ""}
                            onChange={(e) =>
                              commaHandler(e.target.value, setquantity)
                            }
                            className=" bg-tabellenzeilen w-16 border outline-none px-1 rounded-md py-0 text-white"
                          />
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <input
                            type="number"
                            placeholder="0"
                            onChange={(e) => setPrice(e.target.value)}
                            className=" bg-tabellenzeilen w-16 border outline-none px-1 rounded-md py-0 text-white"
                          />
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <div className="w-fit relative">
                            <input
                              type="text"
                              placeholder="0"
                              value={discount || ""}
                              onChange={(e) =>
                                commaHandler(e.target.value, setDiscount)
                              }
                              className=" bg-tabellenzeilen w-16 border outline-none pl-1 pr-6 rounded-md py-0 text-white"
                            />
                            <span className="absolute top-0 right-0 flex items-center pr-1.5 text-sm h-full">
                              %
                            </span>
                          </div>
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <div className="w-fit relative">
                            <input
                              type="text"
                              placeholder="0"
                              value={tax || ""}
                              onChange={(e) =>
                                commaHandler(e.target.value, setTax)
                              }
                              className=" bg-tabellenzeilen w-16 border outline-none px-1 rounded-md py-0 text-white"
                            />
                            <span className="absolute top-0 right-0 flex items-center pr-1.5 text-sm h-full">
                              %
                            </span>
                          </div>
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          {currency === "ETH" ? (
                            <p>{total.toFixed(6)}</p>
                          ) : (
                            <p>{total.toFixed(2)}</p>
                          )}
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <div className=" flex items-center gap-2">
                            <button
                              type="button"
                              onClick={() => insertItem(mainData?.length + 1)}
                              className=" px-2 py-2 flex items-center gap-2 text-white bg-nr rounded-md border-1 border-rahmen shadow-lg hover:bg-blue-500"
                            >
                              <FaSave />
                            </button>
                            {mainData.length !== 0 && (
                              <button
                                type="button"
                                onClick={() => deleteLast()}
                                className=" px-2 py-2 flex items-center gap-2 text-white bg-nr rounded-md border-1 border-rahmen shadow-lg hover:bg-blue-500"
                              >
                                <MdDeleteOutline className=" w-4 h-4" />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className=" w-full grid grid-cols-1 lg:grid-cols-2 gap-10 mt-7 lg:px-10 ">
            <div className=" bg-sr w-full rounded-md p-3 border-1 border-rahmen shadow-lg">
              <div className=" flex items-center justify-between gap-3">
                <p className=" text-white">Payment Option</p>
                <select
                  name=""
                  id=""
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  className=" bg-pr border-none w-52 outline-none px-2 py-2 text-white rounded-md"
                >
                  <option value="ETH">ETH</option>
                  <option value="USDT">USDT</option>
                  <option value="USDC">USDC</option>
                </select>
              </div>
              <div className=" flex items-center justify-between gap-3 mt-2 ">
                <p className=" text-white">Owner</p>
                <input
                  type="text"
                  placeholder="Owner"
                  value={owner}
                  required
                  onChange={(e) => setOwner(e.target.value)}
                  className=" bg-pr border-none w-52 outline-none px-2 py-2 text-white rounded-md"
                />
              </div>
              <div className=" flex items-center justify-between gap-3 mt-2">
                <p className=" text-white">Remarks</p>
                <textarea
                  type="text"
                  required
                  placeholder="Remarks"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  className=" bg-pr border-none w-52 h-28 outline-none px-2 py-2 text-white rounded-md"
                ></textarea>
              </div>
              <div className=" flex items-center justify-between gap-2 mt-2">
                <label className="flex gap-3 items-center text-white cursor-pointer select-none">
                  <input
                    type="checkbox"
                    className="w-5 h-5 accent-nr cursor-pointer"
                  />
                  Private
                </label>
              </div>
            </div>
            <div className=" bg-sr w-full rounded-md p-3 border-1 border-rahmen shadow-lg">
              <div className=" flex items-center justify-between gap-3 mt-2 ">
                <p className=" text-white">Net Total (without discount)</p>
                <input
                  type="text"
                  disabled
                  value={
                    currency === "ETH"
                      ? netTotal.toFixed(6)
                      : netTotal.toFixed(2)
                  }
                  className=" bg-hr w-52 border-none outline-none px-2 py-2 text-white text-right"
                />
              </div>
              <div className=" flex items-center justify-between gap-3 mt-2">
                <p className=" text-white">Discount</p>
                <div className="relative">
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: "100%",
                      color: "white",
                    }}
                  >
                    -
                  </span>
                  <span className=" bg-hr w-52 border-none outline-none px-2 py-2 text-white text-right">
                    {allDiscount === 0
                      ? currency === "ETH"
                        ? (0).toFixed(6)
                        : (0).toFixed(2)
                      : currency === "ETH"
                      ? allDiscount.toFixed(6)
                      : allDiscount.toFixed(2)}
                  </span>
                </div>
              </div>
              <div className=" flex items-center justify-between gap-3 mt-2">
                <p className=" text-white">Tax</p>
                <input
                  type="text"
                  disabled
                  value={
                    currency === "ETH"
                      ? totalTax.toFixed(6)
                      : totalTax.toFixed(2)
                  }
                  className=" bg-hr w-52 border-none outline-none px-2 py-2 text-white text-right"
                />
              </div>
              <div className=" flex items-center justify-between gap-3 mt-2">
                <p className=" text-white">Total</p>
                <input
                  type="text"
                  disabled
                  value={
                    currency === "ETH"
                      ? (allTotal - specialDiscount).toFixed(6)
                      : (allTotal - specialDiscount).toFixed(2)
                  }
                  className=" bg-hr w-52 border-none outline-none px-2 py-2 text-white text-right"
                />
              </div>
            </div>
          </div>

          {isConnected && (
            <div className=" lg:px-10 flex items-center justify-end gap-4 my-4">
              <Link to="/">
                <div
                  type="button"
                  className=" px-6 py-3 flex items-center gap-2 text-white bg-hr rounded-md border-1 border-rahmen shadow-lg hover:bg-red-500"
                >
                  <FaMinusCircle /> Cancel
                </div>
              </Link>
              {processing ? (
                <CircularProgress />
              ) : (
                <button
                  type="submit"
                  className=" px-6 py-3 flex items-center gap-2 text-white bg-nr rounded-md border-1 border-rahmen shadow-lg hover:bg-blue-500"
                >
                  <FaSave /> {mintStatus && mintStatusJson[mintStatus]}
                </button>
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default NewDocument;
