import { Theme, SwapWidget } from "@uniswap/widgets";
import React from "react";

//REFERENCE THEME - https://docs.uniswap.org/sdk/widgets/swap-widget/examples
const theme = {
  primary: "#FFFFFF",
  secondary: "#FFFFFF",
  interactive: "#4269E2",
  container: "#313644",
  module: "#242834",
  accent: "#4269E2",
  outline: "#FFFFFF",
  dialog: "#313644",
  success: "#4269E2",
  fontFamily: "Helvetica",
  borderRadius: 0.5,
};

const MAIN_TOKEN_LIST = [
  {
    name: "Tether USD",
    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    symbol: "USDT",
    decimals: 6,
    chainId: 1,
    logoURI:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
  },
  {
    name: "USD Coin",
    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    symbol: "USDC",
    decimals: 6,
    chainId: 1,
    logoURI:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  },
];

const ResourceSwap = () => {
  return (
    <div className="flex justify-center">
      <div className="max-w-xl px-2">
        <SwapWidget theme={theme} width={"100%"} tokenList={MAIN_TOKEN_LIST} />
      </div>
    </div>
  );
};

export default ResourceSwap;
