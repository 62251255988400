import { Box, Button, Stack } from '@mui/material';
import Card from 'react-bootstrap/Card';
import configData from '../utils/Config';
import moment from "moment"
import { BigNumber, ethers } from "ethers/lib";

import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { useEffect, useState } from 'react';
import { useContract, useProvider, useSigner } from 'wagmi'
import { MdOpenInNew } from "react-icons/md";


const STATUS_ENUM = {0:"OPEN",1:"CLOSED"}
const PAYMENT_MODE_ENUM = {0:"ETH",1:"USDT",2:"USDC"}
const DECIMALS_ENUM = {0:18,1:6,2:6}



function getTime(t){
  var time = moment(t.toNumber()*1000).format("MM-DD-YYYY")
  return time
}

function formatAddress(a){
  var v =a.substring(0,6)+"..."+a.substring(a.length - 4)
  return v
}

function getEtherscanLink(l){
  return configData.etherscanUrl+l
}

function OpenPurchaseCard(props) {

  console.log("PROPS ",props)
  const[tokenId,to,from,openedOn,closedOn,status,paymentMode,amount,invoiceUrl,dummyId,cut] = [props.invoice[0],props.invoice[1],props.invoice[2],props.invoice[3],props.invoice[4],props.invoice[5],props.invoice[6],props.invoice[7],props.invoice[8],props.invoice[9],props.invoice[10]]
  const{address,isConnected} = useAccount()
  const[callWrite,setCallWrite] = useState(false)
  const[approving,setApproving] = useState(false)
  const[minting,setMinting] = useState(false)
  const[hash,setHash] = useState("")

  const provider = useProvider()
  const { data: signer, isError, isLoading } = useSigner()

  const contract = useContract({
    addressOrName: configData.NFTContractAddress,
    contractInterface: configData.abi,
    signerOrProvider: signer,
  })

  async function payFunc(){
    console.log("PAY ASYNC ")
    setMinting(true)
    let b = await contract.payInvoice(tokenId.toNumber())
    console.log("HASH is  ",b)
    setHash(b)
    props.setLastTxn(getEtherscanLink(b.hash))
    let txr = await b.wait()
    if(txr){
      props.setOpen(true)
      setMinting(false)
    }
    console.log("RECIEPT ",txr)
    
  }
  async function payInEthFunc(){
    setMinting(true)
    console.log("PAYIN ETH ASYNC ")
    let b = await contract.payInvoice(tokenId.toNumber(),{value:amount.toString()})
    console.log("HASH is  ",b)
    props.setLastTxn(getEtherscanLink(b.hash))
    let txr = await b.wait()
    if(txr){
      setMinting(false)
      
      props.setOpen(true)
      
    }
    console.log("RECIEPT ",txr)
    
  }

  function getIpfsUrl(){
    
    const convertedGatewayUrl = configData.desiredGatewayPrefix+invoiceUrl;
    
    return convertedGatewayUrl;
  }

  function formatAmount(){
    return ((amount/10**DECIMALS_ENUM[paymentMode]).toFixed(10)).toString().replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1')
  }
  function formatTot(){
    return ((amount/10**DECIMALS_ENUM[paymentMode]).toFixed(10)).toString().replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1')
  }
  const nftContractConfig = {
    addressOrName: configData.NFTContractAddress,
    contractInterface: configData.abi,
  };
  
  const USDCContractConfig = {
    addressOrName: configData.USDCAddress,
    contractInterface: configData.erc20ABI,
  };
  
  const USDTContractConfig = {
    addressOrName: configData.USDTAddress,
    contractInterface: configData.erc20ABI,
  };

 
  //checking usdt approove
  const { data: usdtBalance } = useContractRead({
    ...USDTContractConfig,
    functionName: 'balanceOf',
    watch: true,
    args:[address]
  });

  const { data: usdtApprooved } = useContractRead({
    ...USDTContractConfig,
    functionName: 'allowance',
    watch: true,
    args:[address,configData.NFTContractAddress]
  });
  
  
  const { config: usdtContractWriteConfig } = usePrepareContractWrite({
    ...USDTContractConfig,
    functionName: 'approve',
    args:[configData.NFTContractAddress,amount.toString()],
    
  });
  const { data:approveUSDTData,write:approoveUSDTFunc,status:approveUSDTStatus } = useContractWrite({
    ...usdtContractWriteConfig,
    onSuccess(data) {
      console.log('Success 0 USDT', data)
      props.setLastTxn(getEtherscanLink(data.hash))
      setApproving(true)
    },
    onError(error) {
      console.log('Error 0', error)
      setApproving(false)
    }
  })
  const {
    data: approveUSDTReciept,
    isSuccess: approveUSDTSuccess,
    error: approveUSDTError,
    status: approveUSDTStatus2
  } = useWaitForTransaction({
    hash: approveUSDTData?.hash,
    onSettled(data, error) {
      console.log('Settled USDT Approve', { data, error })
      setApproving(false)
      if(data){
        setMinting(true)
        payFunc?.()
      }
    },
  });

  //checking usdc approove
  const { data: usdcBalance } = useContractRead({
    ...USDCContractConfig,
    functionName: 'balanceOf',
    watch: true,
    args:[address]
  });

  const { data: usdcApprooved } = useContractRead({
    ...USDCContractConfig,
    functionName: 'allowance',
    watch: true,
    args:[address,configData.NFTContractAddress]
  });
  
  
  const { config: usdcContractWriteConfig } = usePrepareContractWrite({
    ...USDCContractConfig,
    functionName: 'approve',
    args:[configData.NFTContractAddress,amount.toString()],
    
  });
  
  const { data:approveUSDCData,write:approoveUSDCFunc,status:approveUSDCStatus } = useContractWrite({
    ...usdcContractWriteConfig,
    onSuccess(data) {
      console.log('Success  USDC', data)
      props.setLastTxn(getEtherscanLink(data.hash))
      setApproving(true)
     
    },
    onError(error) {
      console.log('Error', error)
      setApproving(false)
    }
  })

  const {
    data: approveUSDCReciept,
    isSuccess: approveUSDCSuccess,
    error: approveUSDCError,
    status: approveUSDCStatus2
  } = useWaitForTransaction({
    hash: approveUSDCData?.hash,
    onSettled(data, error) {
      console.log('Settled USDC Approve', { data, error })
      setApproving(false)
      if(data){
        setMinting(true)
        payFunc?.()
      }
    },
  });



  async function preparePayment(){
   
    if(paymentMode==0){
      //pay in eth
      payInEthFunc?.()
      
    }else if(paymentMode==1){
      //pay in usdt
      //check approove
      if(usdtBalance.toNumber()<amount.toNumber()){
        console.log("Dont have enough USDT, buy some")
        props.setShowAlert("Dont have enough USDT, buy some")
        return;
      }
      if(usdtApprooved.toNumber()>=amount.toNumber()){
        console.log("Approve not needed")
        payFunc?.()
      }else{
        console.log("Approve needed")
        approoveUSDTFunc()
      }
      console.log("APPROOVED USDT ",usdtApprooved.toNumber())
      
      
    }else if(paymentMode==2){
      //pay in usdc
      //check approove
      if(usdcBalance.toNumber()<amount.toNumber()){
        console.log("Dont have enough USDC, buy some")
        props.setShowAlert("Dont have enough USDC, buy some")
        return;
      }
      if(usdcApprooved.toNumber()>=amount.toNumber()){
        console.log("Approve not needed")
        
        payFunc?.()
      }else{
        console.log("Approve needed")
        approoveUSDCFunc()
      }
      console.log("APPROOVED USDC ",usdcApprooved.toNumber())
    }else{
      console.log("SOMETHING IS WRONG IN PAYMENT MODE")
      return
    }
  }

  function getFiat(){
    if(paymentMode==0){
      return ethers.utils.formatEther(amount.toString())*props.ethPrice
    }else{
      return amount/10**6
    }
  }


  return (
              
              <tr style={{ background: "#242834" }}>
                <td className="px-6 py-3 whitespace-nowrap">
                  {/* <p>#{props.invoice[9].toNumber()}</p> */}
                  <p>#{props.len - props.index}</p>
                </td>
                <td className="px-6 py-3 whitespace-nowrap">
                  <p>-</p>
                </td>

                <td className="px-6 py-3 whitespace-nowrap">
                  <p>{formatAddress(from)}</p>
                </td>
                <td className="px-6 py-3 whitespace-nowrap">
                  <p>{formatAmount()}</p>
                </td>
                <td className="px-6 py-3 whitespace-nowrap">
                  <p>{PAYMENT_MODE_ENUM[paymentMode]}</p>
                </td>
                <td className="px-6 py-3 whitespace-nowrap">
                  <p>{getFiat().toFixed(2)}$</p>
                </td>
                <td className="px-6 py-3 whitespace-nowrap">
                  <p>{getTime(openedOn)}</p>
                </td>
                <td className="px-6 py-3 text-blue-500 whitespace-nowrap">
                <button onClick={preparePayment} className=" px-2 py-0,5 flex items-center gap-2 text-white bg-nr rounded-md border-1 border-rahmen shadow-lg hover:bg-blue-500"> Pay </button>
                </td>
                <td className="px-6 py-3 text-blue-500 cursor-pointer whitespace-nowrap">
                  
                  <a target="_blank" style={{textDecoration:"none"}} href={getIpfsUrl()} download><MdOpenInNew /></a>
                  
                </td>
              </tr>



        
  );
}

export default OpenPurchaseCard;


// <div>
      
    //   <Card style={{ width: '18rem' }}>
    //   <Card.Body  align="left">
    //   <Card.Title align="center"><b>Invoice ID : {props.invoice[0].toNumber()}</b></Card.Title>
    //     <hr />
    //     <Card.Text>
         
    //      from : {formatAddress(from)} <br/>
    //      opened on : { getTime(openedOn)}<br/>
    //      status : {STATUS_ENUM[status]}<br/>
    //      payment mode : {PAYMENT_MODE_ENUM[paymentMode]}<br/>
    //      take cut from payer: {takeCutFromPayer?.toString()}<br/>
    //      inv amount : {formatAmount()} {PAYMENT_MODE_ENUM[paymentMode]}<br/>
    //      amount payable : {formatTot()} {PAYMENT_MODE_ENUM[paymentMode]}<br/>
    //     </Card.Text>
    //     <Stack direction="row" justifyContent={"space-between"}>
    //     <a target="_blank" style={{textDecoration:"none"}} href={invoiceUrl} download>View Invoice</a>
    //     {console.log("INVOICE URL ",invoiceUrl)}
    //     <Button variant="contained" 
    //     disabled={minting||approving}
    //     onClick={preparePayment}>PAY</Button>
    //     </Stack>
        
    //     {   //TODO : IF YOU NEED SEPERATE ACTIONS FOR APPROVE AND PAY
    //         // paymentMode && paymentMode==0?<Button variant="primary" onClick={makePayment}>PAY</Button>:(
    //         // (paymentMode==1?(usdtApprooved.toNumber()<=amount.toNumber()?<Button>APPROOVE</Button>:<Button>PAY</Button>):
    //         // (usdcApprooved.toNumber()<=amount.toNumber()?<Button>APPROOVE</Button>:<Button>PAY</Button>)))
    //     }
    //   </Card.Body>
    // </Card>
    // </div>