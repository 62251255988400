import { React, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import OpenCard from "./OpenCard";
import { Grid } from "@mui/material";
import configData from "../utils/Config";
import { getAccount } from "@wagmi/core";

import MyClosedCard from "./MyClosedCard";

import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import ClosedCard from "./ClosedCard";

const nftContractConfig = {
  addressOrName: configData.NFTContractAddress,
  contractInterface: configData.abi,
};

// struct INVOICE{
//   uint tokenId;
//   address to;
//   address from;
//   uint openedOn;
//   uint closedOn; //0 initially
//   STATUS status;
//   PAYMENT_MODE paymentMode;
//   uint amount;
//   //string txnHash;
//   string invoiceUrl;
// }

const itemStyle = {
  margin: "20px",
};
const CreatedByMeClosed = () => {
  const { isConnected, address } = useAccount();

  const [ethPrice, setEthPrice] = useState(1);
  const STATUS_ENUM = { 0: "OPEN", 1: "CLOSED" };
  const { data: invCreatedForMe } = useContractRead({
    ...nftContractConfig,
    functionName: "getInvoicesCreatedByMe",
    args: [address],
    watch: true,
    onSuccess(data) {
      console.log("Invoices for me", data);
    },
  });

  const { data: ethPriceRes } = useContractRead({
    ...nftContractConfig,
    functionName: "getEthPrice",
    watch: true,
    onSuccess(data) {
      console.log("ETHPRICE", ethPrice);
      setEthPrice(Math.round(Number(ethPriceRes / 10 ** 8)));
    },
  });

  return (
    <>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
        <div className="py-3 inline-block min-w-full sm:px-6 lg:px-8 ">
          <div className=" overflow-auto lg:overflow-y-scroll h-52  rounded-lg bg-tabelle pb-3 border-1 border-rahmen shadow-lg">
            <table className="min-w-full">
              <thead className="__sticky_thead">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    No.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    Type
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    To Wallet
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    Coin
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    USD
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    Date
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    View
                  </th>
                </tr>
              </thead>
              <tbody className=" text-gray-300 divide-y border-1 border-tabellenlinerahmen">
                {invCreatedForMe &&
                  invCreatedForMe.map((k, index) => {
                    if (k[5] == 1) {
                      return (
                        <MyClosedCard
                          len={invCreatedForMe.length}
                          ethPrice={ethPrice}
                          index={index}
                          invoice={k}
                        />
                      );
                    }
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatedByMeClosed;
