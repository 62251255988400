import { React, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import OpenCard from "./OpenCard";
import { Row, Col } from "react-bootstrap";
import configData from "../utils/Config";
import { getAccount } from "@wagmi/core";
import { Box, Grid, Alert, AlertTitle, Snackbar } from "@mui/material";

import { MdOpenInNew } from "react-icons/md";

import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import OpenPurchaseCard from "./OpenPurchaseCard";
import OpenPurchaseDocumentCard from "./OpenPurchaseDocumentCard";

const nftContractConfig = {
  addressOrName: configData.NFTContractAddress,
  contractInterface: configData.abi,
};

const itemStyle = {
  margin: "20px",
};
const OpenPurchaseDocuments = () => {
  const { isConnected, address } = useAccount();
  const [showAlert, setShowAlert] = useState(null);
  const [open, setOpen] = useState(false);
  const [lastTxn, setLastTxn] = useState(null);
  const [ethPrice, setEthPrice] = useState(1);

  const STATUS_ENUM = { 0: "OPEN", 1: "CLOSED" };
  const { data: invCreatedForMe } = useContractRead({
    ...nftContractConfig,
    functionName: "getInvoicesForMe",
    args: [address],
    watch: true,
    onSuccess(data) {
      console.log("Invoices for me", data);
    },
  });
  const { data: ethPriceRes } = useContractRead({
    ...nftContractConfig,
    functionName: "getEthPrice",
    watch: true,
    onSuccess(data) {
      console.log("ETHPRICE", ethPrice);
      setEthPrice(Math.round(Number(ethPriceRes / 10 ** 8)));
    },
  });
  const { data: d } = useContractRead({
    ...nftContractConfig,
    functionName: "name",
    watch: true,
    onSuccess(data) {
      console.log("NAME ", d);
    },
  });

  // const { data: totalSupplyData } = useContractRead({
  //   ...nftContractConfig,
  //   functionName: 'totalSupply',
  //   watch: true,
  //   onSuccess(data) {
  //     console.log('Success', data)
  //   }
  // });

  // useEffect(()=>{

  // },[invCreatedForMe])

  return (
    <>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8  lg:">
        <div className="py-3 inline-block min-w-full sm:px-6 lg:px-8 ">
          <div className=" overflow-auto lg:overflow-y-scroll h-52  rounded-lg bg-tabelle  border-1 border-rahmen shadow-lg">
            <table className="min-w-full relative">
              <thead className="__sticky_thead">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    No.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    Type
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    From Wallet
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    Coin
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    USD
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    Date
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    View
                  </th>
                </tr>
              </thead>
              <tbody className=" text-gray-300 w-full  divide-y border-1 border-tabellenlinerahmen ">
                {invCreatedForMe &&
                  invCreatedForMe.map((k, index) => {
                    if (k[5] == 0) {
                      return (
                        <OpenPurchaseDocumentCard
                          len={invCreatedForMe.length}
                          ethPrice={ethPrice}
                          index={index}
                          setLastTxn={setLastTxn}
                          setOpen={setOpen}
                          setShowAlert={setShowAlert}
                          invoice={k}
                        />
                      );
                    }
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* <Box className={"outerBox"} align="center">
      <Box sx={{ display: 'flex', p: 1,alignItems:"center"}}>
        <Box style={{ flexGrow: 1 }}><h2>Open Invoices</h2></Box>
      <Box style={{
        p: 1,
        m: 1,
        marginRight:"20px",
        backgroundColor:"white",
        borderRadius:"3px",
        padding:(lastTxn?"3px 10px 3px 10px":"0px"),
        textDecoration:"underline"
        
      }}>    {lastTxn?<a target="_blank" style={{textDecoration:"none"}} href={lastTxn} >Last transaction</a>:null}
      </Box>
      </Box>
   
  <Snackbar open={showAlert} autoHideDuration={6000} onClose={()=>{setShowAlert(null)}}>
    <Alert onClose={()=>{setShowAlert(null)}} severity="error" sx={{ width: '100%' }}>
      {showAlert}
    </Alert>
  </Snackbar>
  <Snackbar open={open} autoHideDuration={10000} onClose={()=>{setOpen(false)}}>
    <Alert onClose={()=>{setOpen(false)}} severity="success" sx={{ width: '100%' }}>
      Payment Successful
    </Alert>
  </Snackbar>
  {/* {showAlert?<Alert severity='error' onClose={() => {setShowAlert(null)}}> <AlertTitle>Error</AlertTitle> <br/>{showAlert}</Alert>:null} */}
      {/* {open ? <ConfettiComponent /> : null} */}
      <Snackbar
        open={open}
        autoHideDuration={10000}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpen(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Payment Successful
        </Alert>
      </Snackbar>

      {/* <Grid align={"center"} container  rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    {invCreatedForMe && invCreatedForMe.map((k) => {

          if(k[5]==0){
          return (
            <Grid item xs={12} sm={3}>
              <OpenCard setLastTxn={setLastTxn} setOpen={setOpen} setShowAlert={setShowAlert} invoice={k}/>
            </Grid>
        )
        }
        
      })}
  </Grid> 
          <Snackbar open={open} autoHideDuration={4000} onClose={()=>setOpen(false)}>
            <Alert severity="success" fullWidth>
                Successfully Paid
            </Alert>
          </Snackbar>
  </Box>

   */}
    </>
  );
};

export default OpenPurchaseDocuments;
