import { Box, Button, Stack } from '@mui/material';
import Card from 'react-bootstrap/Card';
import configData from '../utils/Config';
import moment from "moment"

import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { useEffect, useState } from 'react';
import { useContract, useProvider, useSigner } from 'wagmi'

const STATUS_ENUM = {0:"OPEN",1:"CLOSED"}
const PAYMENT_MODE_ENUM = {0:"ETH",1:"USDT",2:"USDC"}
const DECIMALS_ENUM = {0:18,1:6,2:6}



function getTime(t){
  var time = moment(t.toNumber()*1000).format("DD-MM-YYYY")
  return time
}

function formatAddress(a){
  var v =a.substring(0,6)+"..."+a.substring(a.length - 4)
  return v
}

function getEtherscanLink(l){
  return configData.etherscanUrl+l
}

function OpenCard(props) {

  const[tokenId,to,from,openedOn,closedOn,status,paymentMode,amount,invoiceUrl,dummyId,takeCutFromPayer,cut,tot] = [props.invoice[0],props.invoice[1],props.invoice[2],props.invoice[3],props.invoice[4],props.invoice[5],props.invoice[6],props.invoice[7],props.invoice[8],props.invoice[9],props.invoice[10],props.invoice[11],props.invoice[12]]
  const{address,isConnected} = useAccount()
  const[callWrite,setCallWrite] = useState(false)
  const[approving,setApproving] = useState(false)
  const[minting,setMinting] = useState(false)
  const[hash,setHash] = useState("")

  const provider = useProvider()
  const { data: signer, isError, isLoading } = useSigner()

  const contract = useContract({
    addressOrName: configData.NFTContractAddress,
    contractInterface: configData.abi,
    signerOrProvider: signer,
  })

  async function payFunc(){
    console.log("PAY ASYNC ")
    setMinting(true)
    let b = await contract.payInvoice(tokenId.toNumber())
    console.log("HASH is  ",b)
    setHash(b)
    props.setLastTxn(getEtherscanLink(b.hash))
    let txr = await b.wait()
    if(txr){
      props.setOpen(true)
      setMinting(false)
    }
    console.log("RECIEPT ",txr)
    
  }
  async function payInEthFunc(){
    setMinting(true)
    console.log("PAYIN ETH ASYNC ")
    let b = await contract.payInvoice(tokenId.toNumber(),{value:tot.toString()})
    console.log("HASH is  ",b)
    props.setLastTxn(getEtherscanLink(b.hash))
    let txr = await b.wait()
    if(txr){
      setMinting(false)
      
      props.setOpen(true)
      
    }
    console.log("RECIEPT ",txr)
    
  }

  function formatAmount(){
    return ((amount/10**DECIMALS_ENUM[paymentMode]).toFixed(10)).toString().replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1')
  }
  function formatTot(){
    return ((tot/10**DECIMALS_ENUM[paymentMode]).toFixed(10)).toString().replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1')
  }
  const nftContractConfig = {
    addressOrName: configData.NFTContractAddress,
    contractInterface: configData.abi,
  };
  
  const USDCContractConfig = {
    addressOrName: configData.USDCAddress,
    contractInterface: configData.erc20ABI,
  };
  
  const USDTContractConfig = {
    addressOrName: configData.USDTAddress,
    contractInterface: configData.erc20ABI,
  };

  //preparing contract
  // const { config: payInvoiceConfig } = usePrepareContractWrite({
  //   ...nftContractConfig,
  //   functionName: 'payInvoice',
  //   args:[tokenId.toNumber()],
    
  // });

  // const { write,status:payInvoiceStatus } = useContractWrite({
  //   ...payInvoiceConfig,
  //   onSuccess(data, error) {
  //     console.log('PAY RES', { data, error })
  //   },
  // })

  
  // const { config: payContractWriteConfig } = usePrepareContractWrite({
  //   ...nftContractConfig,
  //   functionName: 'payInvoice',
  //   args:[tokenId.toNumber()],
    
  // });
  // const { config: payInEthContractWriteConfig } = usePrepareContractWrite({
  //   ...nftContractConfig,
  //   functionName: 'payInvoice',
  //   args:[tokenId.toNumber(),{value:amount.toString()}],
    
  // });
  // const { data:payData,write:payFunc,status:payInvoiceStatus } = useContractWrite({
  //   ...payContractWriteConfig,
  //   onSuccess(data) {
  //     console.log('Success PAY', data)
  //     setMinting(true)
      
  //   },
  //   onError(error) {
  //     setMinting(false)
  //     console.log('Error', error)
  //   }
  // })
  // const { data:payInEthData,write:payInEthFunc,status:payInEthInvoiceStatus } = useContractWrite({
  //   ...payInEthContractWriteConfig,
    
  //   // overrides: {
  //   //   from: address,
  //   //   value: amount,
  //   // },
  //   onSuccess(data) {
  //     console.log('Success PAY', data)
  //     setMinting(true)
  //   },
  //   onError(error) {
  //     console.log('Error', error)
  //     setMinting(false)
  //   }
  // })

  // const {
  //   data: payReceipt,
  //   isSuccess: txSuccess,
  //   error: txError,
  //   status: payStatus
  // } = useWaitForTransaction({
  //   hash: hash?.hash,
  //   onSettled(data, error) {
  //     console.log('Settled Pay', { data, error })
  //     if(data){
  //       props.setOpen(true)
  //     }
  //     setMinting(false)
  //   },
  // });

  // const {
  //   data: payInEthReceipt,
  //   isSuccess: tx2Success,
  //   error: tx2Error,
  //   status: payInEthStatus
  // } = useWaitForTransaction({
  //   hash: payInEthData?.hash,
  //   onSettled(data, error) {
  //     if(data){
  //       props.setOpen(true)
  //     }
  //     console.log('Settled Pay', { data, error })
  //     setMinting(false)
  //   },
  // });



  //checking usdt approove
  const { data: usdtBalance } = useContractRead({
    ...USDTContractConfig,
    functionName: 'balanceOf',
    watch: true,
    args:[address]
  });

  const { data: usdtApprooved } = useContractRead({
    ...USDTContractConfig,
    functionName: 'allowance',
    watch: true,
    args:[address,configData.NFTContractAddress]
  });
  
  
  const { config: usdtContractWriteConfig } = usePrepareContractWrite({
    ...USDTContractConfig,
    functionName: 'approve',
    args:[configData.NFTContractAddress,tot.toString()],
    
  });
  const { data:approveUSDTData,write:approoveUSDTFunc,status:approveUSDTStatus } = useContractWrite({
    ...usdtContractWriteConfig,
    onSuccess(data) {
      console.log('Success 0 USDT', data)
      props.setLastTxn(getEtherscanLink(data.hash))
      setApproving(true)
    },
    onError(error) {
      console.log('Error 0', error)
      setApproving(false)
    }
  })
  const {
    data: approveUSDTReciept,
    isSuccess: approveUSDTSuccess,
    error: approveUSDTError,
    status: approveUSDTStatus2
  } = useWaitForTransaction({
    hash: approveUSDTData?.hash,
    onSettled(data, error) {
      console.log('Settled USDT Approve', { data, error })
      setApproving(false)
      if(data){
        setMinting(true)
        payFunc?.()
      }
    },
  });

  //checking usdc approove
  const { data: usdcBalance } = useContractRead({
    ...USDCContractConfig,
    functionName: 'balanceOf',
    watch: true,
    args:[address]
  });

  const { data: usdcApprooved } = useContractRead({
    ...USDCContractConfig,
    functionName: 'allowance',
    watch: true,
    args:[address,configData.NFTContractAddress]
  });
  
  
  const { config: usdcContractWriteConfig } = usePrepareContractWrite({
    ...USDCContractConfig,
    functionName: 'approve',
    args:[configData.NFTContractAddress,tot.toString()],
    
  });
  
  const { data:approveUSDCData,write:approoveUSDCFunc,status:approveUSDCStatus } = useContractWrite({
    ...usdcContractWriteConfig,
    onSuccess(data) {
      console.log('Success  USDC', data)
      props.setLastTxn(getEtherscanLink(data.hash))
      setApproving(true)
     
    },
    onError(error) {
      console.log('Error', error)
      setApproving(false)
    }
  })

  const {
    data: approveUSDCReciept,
    isSuccess: approveUSDCSuccess,
    error: approveUSDCError,
    status: approveUSDCStatus2
  } = useWaitForTransaction({
    hash: approveUSDCData?.hash,
    onSettled(data, error) {
      console.log('Settled USDC Approve', { data, error })
      setApproving(false)
      if(data){
        setMinting(true)
        payFunc?.()
      }
    },
  });



  async function preparePayment(){
   
    if(paymentMode==0){
      //pay in eth
      payInEthFunc?.()
      
    }else if(paymentMode==1){
      //pay in usdt
      //check approove
      if(usdtBalance.toNumber()<tot.toNumber()){
        console.log("Dont have enough USDT, buy some")
        props.setShowAlert("Dont have enough USDT, buy some")
        return;
      }
      if(usdtApprooved.toNumber()>=tot.toNumber()){
        console.log("Approve not needed")
        payFunc?.()
      }else{
        console.log("Approve needed")
        approoveUSDTFunc()
      }
      console.log("APPROOVED USDT ",usdtApprooved.toNumber())
      
      
    }else if(paymentMode==2){
      //pay in usdc
      //check approove
      if(usdcBalance.toNumber()<tot.toNumber()){
        console.log("Dont have enough USDC, buy some")
        props.setShowAlert("Dont have enough USDC, buy some")
        return;
      }
      if(usdcApprooved.toNumber()>=tot.toNumber()){
        console.log("Approve not needed")
        
        payFunc?.()
      }else{
        console.log("Approve needed")
        approoveUSDCFunc()
      }
      console.log("APPROOVED USDC ",usdcApprooved.toNumber())
    }else{
      console.log("SOMETHING IS WRONG IN PAYMENT MODE")
      return
    }
  }


  return (
    <div>
      {/* <p>Pay in ETh {payInEthStatus}</p>
      <p>Pay status {payStatus}</p>
      {approveUSDTStatus && (<p>STATUS USDT {approveUSDTStatus2}</p>)}<br/>
      {approveUSDCStatus && (<p>STATUS USDC {approveUSDCStatus2}</p>)}<br/>
      {payInvoiceStatus && (<p>PAY STATUS {payInvoiceStatus}</p>)} */}
      <Card style={{ width: '18rem' }}>
      <Card.Body  align="left">
      <Card.Title align="center"><b>Invoice ID : {props.invoice[0].toNumber()}</b></Card.Title>
        <hr />
        <Card.Text>
         
         from : {formatAddress(from)} <br/>
         opened on : { getTime(openedOn)}<br/>
         status : {STATUS_ENUM[status]}<br/>
         payment mode : {PAYMENT_MODE_ENUM[paymentMode]}<br/>
         take cut from payer: {takeCutFromPayer?.toString()}<br/>
         inv amount : {formatAmount()} {PAYMENT_MODE_ENUM[paymentMode]}<br/>
         tot payable : {formatTot()} {PAYMENT_MODE_ENUM[paymentMode]}<br/>
        </Card.Text>
        <Stack direction="row" justifyContent={"space-between"}>
        <a target="_blank" style={{textDecoration:"none"}} href={invoiceUrl} download>View Invoice</a>
        {console.log("INVOICE URL ",invoiceUrl)}
        <Button variant="contained" 
        disabled={minting||approving}
        onClick={preparePayment}>PAY</Button>
        </Stack>
        
        {   //TODO : IF YOU NEED SEPERATE ACTIONS FOR APPROVE AND PAY
            // paymentMode && paymentMode==0?<Button variant="primary" onClick={makePayment}>PAY</Button>:(
            // (paymentMode==1?(usdtApprooved.toNumber()<=amount.toNumber()?<Button>APPROOVE</Button>:<Button>PAY</Button>):
            // (usdcApprooved.toNumber()<=amount.toNumber()?<Button>APPROOVE</Button>:<Button>PAY</Button>)))
        }
      </Card.Body>
    </Card>
    </div>
  );
}

export default OpenCard;