import React from 'react'
import { MdOpenInNew } from "react-icons/md";
import CreatedByMeClosed from '../components/CreatedByMeClosed';
import CreatedByMeOpen from '../components/CreatedByMeOpen';

const Sales_document = () => {
  return (
    <div className='pl-2 lg:pl-20 lg:pr-20 pr-6'>
             <h1 className=" text-3xl  text-white w-full text-center pb-10 pt-6">
             <span className=" text-nr">Sales</span> Documents
        </h1>
             <h1 className=" text-3xl  text-white ">
             <span className=" text-nr">Open</span> Sales Documents
        </h1>
        <OpenDoucment/>
             <h1 className=" text-3xl  text-white  mt-5">
             <span className=" text-nr">Closed</span> Sales Documents
        </h1>
        <CloseDoucment/>
    </div>
  )
}

const OpenDoucment = () => {

    return (
      <CreatedByMeOpen/>
    );
  };
  
const CloseDoucment = () => {

    return (
      <CreatedByMeClosed/>
    );
  };

export default Sales_document



 
  