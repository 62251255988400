
import Card from 'react-bootstrap/Card';
import configData from '../utils/Config';
import moment from "moment"
import Divider from '@mui/material/Divider';
import { Box, Button, Stack } from '@mui/material';

import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';


const STATUS_ENUM = {0:"OPEN",1:"CLOSED"}
const PAYMENT_MODE_ENUM = {0:"ETH",1:"USDT",2:"USDC"}
const DECIMALS_ENUM = {0:18,1:6,2:6}


const nftContractConfig = {
  addressOrName: configData.NFTContractAddress,
  contractInterface: configData.abi,
};

const USDCContractConfig = {
  addressOrName: configData.USDCAddress,
  contractInterface: configData.erc20ABI,
};

const USDTContractConfig = {
  addressOrName: configData.USDTAddress,
  contractInterface: configData.erc20ABI,
};



function getTime(t){
  var time = moment(t.toNumber()*1000).format("DD-MM-YYYY")
  // var time = moment(t.toNumber()*1000).format("DD-MM-YYYY h:mm a")
  return time
}

function formatAddress(a){
  var v =a.substring(0,6)+"..."+a.substring(a.length - 4)
  return v
}


function MyOpenCard(props) {

  const[tokenId,to,from,openedOn,closedOn,status,paymentMode,amount,invoiceUrl,dummyId,takeCutFromPayer,cut] = [props.invoice[0],props.invoice[1],props.invoice[2],props.invoice[3],props.invoice[4],props.invoice[5],props.invoice[6],props.invoice[7],props.invoice[8],props.invoice[9],props.invoice[10],props.invoice[11]]
  const{address,isConnected} = useAccount()

 

  function formatAmount(){
    return ((amount/10**DECIMALS_ENUM[paymentMode]).toFixed(10)).toString().replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1')
  }

  return (
    <div>
      {/* {approveUSDTStatus && (<p>STATUS USDC {approveUSDTStatus}</p>)}<br/>
      {payInvoiceStatus && (<p>PAY STATUS {payInvoiceStatus}</p>)} */}
      <Card style={{ width: '18rem' }}>
      <Card.Body align="left">
        <Card.Title align="center"><b>Invoice ID : {props.invoice[0].toNumber()}</b></Card.Title>
        <hr />
        <Card.Text>
         
         to : {formatAddress(to)} <br/>
         opened on : { getTime(openedOn)}<br/>
         status : {STATUS_ENUM[status]}<br/>
         payment mode : {PAYMENT_MODE_ENUM[paymentMode]}<br/>
         amount : {formatAmount()} {PAYMENT_MODE_ENUM[paymentMode]}<br/>
        </Card.Text>
        <a target="_blank" style={{textDecoration:"none"}} href={invoiceUrl} download>View Invoice</a>
        {console.log("INVOICE URL ",invoiceUrl)}
        
        {   //TODO : IF YOU NEED SEPERATE ACTIONS FOR APPROVE AND PAY
            // paymentMode && paymentMode==0?<Button variant="primary" onClick={makePayment}>PAY</Button>:(
            // (paymentMode==1?(usdtApprooved.toNumber()<=amount.toNumber()?<Button>APPROOVE</Button>:<Button>PAY</Button>):
            // (usdcApprooved.toNumber()<=amount.toNumber()?<Button>APPROOVE</Button>:<Button>PAY</Button>)))
        }
        {/* <Button variant="primary" onClick={preparePayment}>PAY</Button> */}
      </Card.Body>
    </Card>
    </div>
  );
}

export default MyOpenCard;